var apiSettings = {
  apiUrl: 'https://iqicdb.chboston.org/c3por3webapi/api/'
  //apiUrl: 'https://iqictestdmz.chboston.org/c3por3webapi/api/'

}
var SurgeryapiSettings = {
  iqisapiurl: 'https://iqicdb.chboston.org/webapi/api/'
  //iqisapiurl: 'https://iqictestdmz.chboston.org/webapi/api/'

}
